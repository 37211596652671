<template>
  <div>
    <HgAmeropaSearch
      :is-mobile="isMobile"
      :departures="departures"
      :destinations="destinations"
      :is-loading-departures="isLoadingDepartures"
      :is-loading-destinations="isLoadingDestinations"
      :search-url="searchUrl"
      @onUpdateDepartureTerm="updateDepartureTerm"
      @onUpdateDestinationTerm="updateDestinationTerm"
      @onUpdateSearchParameters="updateSearchParameters"
      @onTriggerSearch="triggerSearch"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { addDays } from 'date-fns';
import GtmEventsMixin from '@/mixins/GtmEventsMixin';
import ameropaRailstationAutosuggestQuery from '@/apollo/queries/ibe/ameropaRailstationAutosuggest.gql';
import ameropaDestinationAutosuggestQuery from '@/apollo/queries/ibe/ameropaDestinationAutosuggest.gql';
import ameropaHotelAutosuggestQuery from '@/apollo/queries/ibe/ameropaHotelAutosuggest.gql';
import ameropaSearchUrlQuery from '@/apollo/queries/ibe/ameropaSearchUrl.gql';

export default {
  mixins: [GtmEventsMixin],
  data: () => ({
    departureTerm: '',
    destinationTerm: '',
    isLoadingDepartures: false,
    isLoadingDestinations: false,
    departures: [],
    destinations: [],
    searchUrl: ''
  }),
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile
    })
  },
  methods: {
    updateDepartureTerm(term) {
      this.departureTerm = term;
      this.isLoadingDepartures = true;
      this.queryDepartures();
    },
    updateDestinationTerm(term) {
      this.destinationTerm = term;
      this.isLoadingDestinations = true;
      this.queryDestinations();
    },
    async updateSearchParameters(event) {
      const { departure, destination, dateRange, travelers, duration } = event;

      if (
        departure == null ||
        destination == null ||
        dateRange == null ||
        travelers == null ||
        duration == null
      ) {
        return;
      }

      delete departure.__typename;
      delete destination.__typename;

      let rooms = [];

      for (let i = 0; i < travelers.rooms; i++) {
        let adults = travelers.adults / travelers.rooms;
        let ageOfChildren = travelers.ages.slice(
          i,
          i + travelers.ages.length / travelers.rooms
        );

        rooms.push({
          adults,
          ageOfChildren
        });
      }

      try {
        const {
          data: { ameropaSearchUrl }
        } = await this.$apollo.query({
          query: ameropaSearchUrlQuery,
          variables: {
            input: {
              cityLabel: destination.type == 'location' ? destination.name : '',
              productCode:
                destination.type == 'hotel' ? destination.productCode : '',
              duration,
              startDate: addDays(dateRange.start, 1),
              endDate: addDays(dateRange.end, 1),
              fromLocation: departure?.eva?.toString(),
              rooms
            }
          }
        });

        this.searchUrl = ameropaSearchUrl;
      } catch (e) {
        console.error(e);
      }
    },
    triggerSearch(event) {
      this.$gtm.push({
        event: 'ameropa_search_used',
        period: `${this.$dateFns.format(
          event.dateRange.start,
          'EE, dd.MM.yyyy'
        )} - ${this.$dateFns.format(event.dateRange.end, 'EE, dd.MM.yyyy')}`,
        departure: this.departureTerm,
        destination: this.destinationTerm,
        travellers: {
          adults: event.travelers.adults,
          children: event.travelers.ages.length,
          ages: event.travelers.ages,
          rooms: event.travelers.rooms
        },
        duration: event.duration.replace('-', ',')
      });
    },
    async queryDepartures() {
      if (this.departureTerm === '') {
        this.isLoadingDepartures = false;
        return;
      }

      try {
        const {
          data: { ameropaRailstationAutosuggest }
        } = await this.$apollo.query({
          query: ameropaRailstationAutosuggestQuery,
          variables: {
            query: this.departureTerm
          }
        });

        if (
          ameropaRailstationAutosuggest &&
          ameropaRailstationAutosuggest.length > 0
        ) {
          this.departures = ameropaRailstationAutosuggest;
        }
      } catch (e) {
        console.error(e.graphQLErrors);
      } finally {
        this.isLoadingDepartures = false;
      }
    },
    async queryDestinations() {
      if (this.destinationTerm === '') {
        this.isLoadingDestinations = false;
        return;
      }

      let newDestinations = []

      try {
        const {
          data: { ameropaDestinationAutosuggest }
        } = await this.$apollo.query({
          query: ameropaDestinationAutosuggestQuery,
          variables: {
            query: this.destinationTerm
          }
        });

        if (
          ameropaDestinationAutosuggest &&
          ameropaDestinationAutosuggest.length > 0
        ) {
          for (let destination of ameropaDestinationAutosuggest) {
            destination.type = 'location';
            newDestinations.push(destination);
          }
        }
      } catch (e) {
        console.error(e.graphQLErrors);
      } finally {
        this.isLoadingDestinations = false;
      }

      try {
        const {
          data: { ameropaHotelAutosuggest }
        } = await this.$apollo.query({
          query: ameropaHotelAutosuggestQuery,
          variables: {
            query: this.destinationTerm
          }
        });

        if (ameropaHotelAutosuggest && ameropaHotelAutosuggest.length > 0) {
          for (let destination of ameropaHotelAutosuggest) {
            destination.type = 'hotel';
            newDestinations.push(destination);
          }
        }
      } catch (e) {
        console.error(e.graphQLErrors);
      } finally {
        this.isLoadingDestinations = false;
      }

      this.destinations = newDestinations
    }
  }
};
</script>
